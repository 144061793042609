<template>
  <div class="validate-bank-transaction-csv">
    <div class="vx-row">
      <vx-card title="Bank Transaktionen - CSV Mapping">
        <div class="centerx">
          <vs-row>
            <vs-col vs-type="flex" vs-align="center" vs-w="12">
              <h6 class="mb-4">Bitte prüfe, ob die Felder passend sind und ob alle Daten vorhanden sind:</h6>
            </vs-col>
          </vs-row>

          <b>Ausgewählte Datei: {{importModel.file }}</b>


          <!-- mg-select für die Auswahl des Bankkontos -->
          <vs-row vs-w="6" class="mt-4 mb-4">
              <b>Bankkonto zurordnen</b>
              <mg-select v-model="selectedBankAccountId" :options="bankAccounts" name="bank_account"
                         placeholder="Bankkonto auswählen"
                         track-by="id" label="label" data-vv-as="Bankkonto" v-validate="'required'">
              </mg-select>

              <vs-alert color="#f83b3b" :active="true" icon="info" class="mb-4 mt-4 "
                        v-show="!selectedBankAccountId">
                Bitte Bankkonto zuordnen
              </vs-alert>

          </vs-row>


          <vs-row vs-w="12">
            <vs-table :data="dataImportModels" vs-col="12" >
              <template slot="header">
                <h3>
                  Daten
                </h3>
              </template>
              <template slot="thead">
                <vs-th v-for="(value,key) in dataImportModels">
                  {{ key }}
                </vs-th>
              </template>
              <template slot-scope="dataImportModels">
                <vs-tr v-for="(csvModelRow,indextr) in dataImportCsv"  :data="csvModelRow" :key="indextr">
                  <vs-td v-for="(modelDataIn,key) in csvModelRow">
                    <div :class="getClassName(modelDataIn,key)" class="table-cell">
                      <span v-if="key != 'client_id'">{{ modelDataIn }}</span>
                      <span v-if="getClassName(modelDataIn,key) === 'error' && !modelDataIn">NICHT GEFÜLLLT</span>

                      <div v-if="key === 'client_id'">
                        <mg-select :options="clients" name="client" v-model="csvModelRow[key]"

                                   placeholder="Kunde auswählen"
                                   track-by="id" label="company_name" data-vv-as="Kunde" >
                        </mg-select>
                        <span class="link" v-if="showIBANAddToClient(csvModelRow,key)" @click="onClickAddIBANToClient(csvModelRow['client_id'],csvModelRow['iban'],csvModelRow['bic'])"><small>IBAN/BIC in Kundenstammdaten übernehmen</small></span>
                        <span v-if="showIBANAddToClient(csvModelRow,key)"><br><small>Aktuell: <i>{{ getIBANfromClient(csvModelRow['client_id'])}}</i></small></span>
                      </div>

                    </div>

                  </vs-td>
                </vs-tr>

              </template>
            </vs-table>
          </vs-row>

        </div>

        <vs-alert color="#f83b3b" :active="true" icon="info" class="mb-4 mt-4 "
                  v-show="!selectedBankAccountId">
          Bitte Bankkonto zuordnen
        </vs-alert>

        <vs-button color="primary"
                   class="mr-2"
                   type="border"
                   @click="fetchInitialClients()"
        >Kundenliste aktualisieren</vs-button>
        <vs-button color="primary"
                   type="filled"
                   @click="openPromtToImport()"
        >Alle Banktransaktionen importieren</vs-button>

        <vs-alert color="danger" :active="importedErrors >= 1" icon="info" class="mb-4 mt-2">
         Es konnten insgesamt {{importedErrors}} Bank Transaktionen nicht importiert werden
        </vs-alert>

        <vs-alert color="success" :active="importedSuccessful >= 1" icon="info" class="mb-4">
         Es  wurden insgesamt {{importedSuccessful}} Bank Transaktionen erfolgreich importiert
        </vs-alert>


      </vx-card>



      <vs-prompt
          @accept="importAllBankTransactions"
          title="Bank Transactionen importieren"
          @close="ImportBankTransactionsPrompt=false"
          acceptText="Ja, importieren"
          cancelText="Abbrechen"
          :active.sync="ImportBankTransactionsPrompt">
        <p>Sind Sie sicher dass die BANK-Transaktionen <u>endgültig</u> importiert werden sollen</p>
      </vs-prompt>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../api/index";
import MgSelect from "../../../components/mg-select/MgSelect";
import PriceHelper from "@/mixins/helper/price.helper";

export default {
  name: "ValidateBankTransactionsCSV",
  components: {
    MgSelect,
  },
  created() {
    this.fetchInitialClients();
    this.fetchImportData();
    this.fetchImportModel().then((response) => {
      this.fetchBankAccounts();
    });

  },
  data() {
    return {
      selected: [],
      dataImportCsv: [],
      clients: [],
      dataImportModels: [],
      newModelData: '',
      ImportBankTransactionsPrompt: false,
      importedErrors: 0,
      importedSuccessful: 0,
      importModel:[],
      bankAccounts: [],
      selectedBankAccountId: null,
    }
  },
  methods: {
    fetchInitialClients() {
      ApiService.get('clients')
          .then((response) => {
            if (response.data.status === 'success') {
              this.clients = response.data.result;
            }
          });
    },
    showErrorMessage(text) {
      this.$vs.notify({
        title: 'Fehler',
        text: text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'warning'
      });
    },
    showSuccessMessage(text) {
      this.$vs.notify({
        title: 'Erfolgreich',
        text: text,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'success'
      });
    },
    getClassName(value,key) {

      if (value === ""  || value == "N/A" || value === null){
        return "error";
      }
    },
    showIBANAddToClient(row,key){
      if (!row["client_id"]) {
          return false;
      }
      let selectedClient = null;

      this.clients.forEach(function (client) {
        if(client.id === row["client_id"]) {
          selectedClient = client;
        }
      })
      if (selectedClient == null) {
        return false;
      }
      if (row["iban"] !== selectedClient.bank_iban || selectedClient.bank_iban === "") {
        return  true;
      }
      return false;
    },
    getIBANfromClient(clientId){
      if (!clientId) {
          return "nicht gefüllt";
      }
      let selectedClient = null;

      this.clients.forEach(function (client) {
        if(client.id === clientId) {
          selectedClient = client;
        }
      })
      if (selectedClient == null) {
        return "<nicht gefüllt>";
      }
      if (selectedClient.bank_iban) {
        return  selectedClient.bank_iban;
      }
      return "<nicht gefüllt>";
    },
    openPromtToImport(){
      this.ImportBankTransactionsPrompt = true;
    },
    createRequest(){

       this.dataImportCsv.forEach(function (banktransaction,index) {
         banktransaction.bank_account_id = this.selectedBankAccountId;

         ApiService.post('bank-transactions', banktransaction).then(response => {

          if(response.data.status === 'success') {
            this.removeItemRow(index)
            return this.importedSuccessful++;
          }
          return this.importedErrors++
        }).catch((error) => {
          return this.importedErrors++
        })
      }, this)
    },
    importAllBankTransactions(){
      this.importedSuccessful = 0
      this.importedErrors = 0
      this.createRequest();
      this.ImportBankTransactionsPrompt = false;

    },
    onClickAddIBANToClient(clientId,iban,bic){

      const payload = {
        bank_iban: iban,
        bank_bic: bic
      };

      ApiService.put('clients/' + clientId, payload).then(response => {

        if(response.data.status === 'success') {
          return this.$vs.notify({
            title: 'Erfolgreich',
            text: 'Die Bankdaten wurden beim Kunden/Lieferant erfolgreich hinterlegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          })
        }

        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Bankdaten konnten nicht hinterlegt werden',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

      }).catch((error) => {
        this.$vs.notify({
          title: 'Fehlgeschlagen',
          text: 'Die Bankdaten konnten nicht hinterlegt werden',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })

    },
    fetchImportModel(){
      if (!this.$route.params.fileId) {
        return this.showErrorMessage("keine File Id")
      }

      return ApiService.get('data-import/'+this.$route.params.fileId).then(response => {

        if (!response.data.result){
          this.showErrorMessage("keine Daten gefunden")
        }
        this.importModel = response.data.result;
      }).catch(response => {
        this.showErrorMessage("keine Daten gefunden")
      })
    },
    fetchImportData() {
      if (!this.$route.params.fileId) {
        this.showErrorMessage("keine File Id")
      }

      ApiService.get('data-import/bank-transactions/validate/'+this.$route.params.fileId).then(response => {

        if (!response.data.result){
          this.showErrorMessage("keine Daten gefunden")
        }
        this.dataImportCsv = response.data.result;
        this.dataImportModels = JSON.parse(JSON.stringify(response.data.result[0]));
      }).catch(response => {
        this.showErrorMessage("keine Daten gefunden")
      })
    },
    removeItemRow: function (index) {
      this.dataImportCsv.splice(index, 1);
    },
    async fetchBankAccounts() {
      try {
        const response = await  ApiService.get('/bank-account');
        if (response.data.status === 'success') {
          this.bankAccounts = response.data.result;

          // Vorschlag auf Basis des Dateinamens
          const filename = this.getFileName(); // Implementiere die Methode, um den Dateinamen zu erhalten
          this.suggestBankAccountBasedOnFilename(filename);
        }
      } catch (error) {
        console.error('Fehler beim Abrufen der Bankkonten:', error);
      }
    },
    suggestBankAccountBasedOnFilename(filename) {
      // Beispiel: Wenn der Dateiname das Konto enthält, setze es als ausgewähltes Konto
      const suggestedAccount = this.bankAccounts.find(account => filename.includes(account.account_number));
      if (suggestedAccount) {
        this.selectedBankAccountId = suggestedAccount.id;
      }
    },
    getFileName() {
      if (this.importModel.file){
        return this.importModel.file;
      } else {
        console.error(this.importModel, "importmodel.file nicht erreichbar");
      }
    },
  }
}
</script>

<style lang="scss" >
.validate-bank-transaction-csv {

  .table-cell.error{
    background: red;
    color:#fff;
    position: relative;
    min-width: 20px;
    max-height: 20px;
  }

  .con-tablex.vs-table--content,
  .vs-con-table .vs-con-tbody{
    overflow: visible;
  }

  .multiselect.mg-select {
    position: relative;
    overflow: visible;
  }

  .multiselect__content-wrapper  {
    z-index: 77777;
  }

}
</style>
